import React, { useEffect } from "react";
import "./App.css";
import Navbar from "./components/Navbar";
import Page1 from "./views/Page1";
import Page2 from "./views/Page2";
import Page3 from "./views/Page3";
import AOS from "aos";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 2000,
      mirror: true,
    });
  }, []);

  return (
    <>
      <Navbar />
      <Page1 />
      <Page2 />
      <Page3 />
    </>
  );
}

export default App;
