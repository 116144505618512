import React from "react";
import Image from "../assets/img/logo-rose.png";
import Home from "../assets/img/home.svg";
import Creations from "../assets/img/creations.svg";
import Contact from "../assets/img/contact.svg";
import { Navbar, Nav, Container } from "react-bootstrap";

function MyNavbar() {
  const closeNav = () => {
    const nav = document.getElementById("basic-navbar-nav");
    nav.classList.remove("show");
  };

  return (
    <Navbar bg="light" expand="lg" sticky="top">
      <Container>
        <Navbar.Brand href="#home">
          <img
            src={Image}
            id="logo"
            className="d-inline-block align-top"
            alt="Logo Andréa Bousseau"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse
          id="basic-navbar-nav"
          className="justify-content-center"
        >
          <Nav className="me-auto">
            <Nav.Link href="#home" onClick={closeNav}>
              <img src={Home} alt="Home" style={{ width: "80px" }} />
            </Nav.Link>
            <Nav.Link href="#creations" onClick={closeNav}>
              <img src={Creations} alt="Créations" style={{ width: "80px" }} />
            </Nav.Link>
            <Nav.Link href="#contact" onClick={closeNav}>
              <img src={Contact} alt="Contact" style={{ width: "80px" }} />
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default MyNavbar;
