import React from "react";
import contact from "../assets/img/contact.svg";
import instagram from "../assets/img/instagram.svg";
import linkedin from "../assets/img/linkedin.svg";

function Page3(){
    return(
        <section id="contact" className="d-flex flex-column align-items-center bg-primary">
            <img src={contact} alt="Contact" style={{width: '250px'}} />
            <div className="contact col-md-3 row d-flex flex-column align-items-center mt-5">
                <a href="mailto:andreabousseau08@gmail.com">andreabousseau08@gmail.com</a>
                <div className="logo d-flex col-md-8 justify-content-around">
                    <div>
                        <a href="http://https://www.instagram.com/x_andream_x/?hl=fr" target="_blank">
                            <img src={instagram} alt="logo instagram" />
                        </a>
                    </div>
                    <div>
                        <a href="http://https://www.linkedin.com/in/andr%C3%A9a-bousseau-805651199/" target="_blank">
                            <img src={linkedin} alt="logo linkedin" />
                        </a>
                    </div>
                </div>
            </div>
            <div id="credits">
                <h6>©2021 ~ Andréa Bousseau ~ Mentions légales</h6>
            </div>
        </section>
    );
}

export default Page3;