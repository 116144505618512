import React from "react";

function CreationFullPage({image, description, titre, handleShow}) {
    return(
        <section data-aos='zoom-in-up' data-aos-duration="300" id="modal" className="d-flex flex-row" onClick={() => handleShow(false)}>
            <div className="col-md-6 col-12 d-flex justify-content-center align-items-center">
                <img src={image} alt="whaaaat" className="imgModal"/>
            </div>
            <div className="col-md-6 col-12 d-flex flex-column justify-content-center align-items-center">
                <h3>{titre}</h3>
                <div className="p-4">{description}</div>
            </div>
        </section>
    );
}

export default CreationFullPage;