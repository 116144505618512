import React, { useState } from "react";
import creations from "../assets/img/creations.svg";
import illustration1 from "../assets/img/illustration_1.png";
import illustration2 from "../assets/img/illustration_2.png";
import illustration3 from "../assets/img/illustration_3.png";
import illustration4 from "../assets/img/illustration_4.png";
import illustration5 from "../assets/img/illustration_5.png";
import illustration6 from "../assets/img/illustration_6.png";
import illustration7 from "../assets/img/illustration_7.png";
import illustration8 from "../assets/img/illustration_8.png";
import CreationFullPage from "../components/CreationFullPage";

function Page2() {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [imageToDisplay, setImageToDisplay] = useState();
  const [show, setShow] = useState();

    const renderDescription = (description) => {
        const lines = description.split('\n');
        return lines.map((elem, index) => (
            <p key={index}>{elem}</p>
        ));
    }

  const handleShowCreation = (image, titre, description) => {

    document.body.classList.add('no-scroll');

    setDescription(renderDescription(description));
    setImageToDisplay(image);
    setShow(true);
    setTitle(titre);
  };

  const handleShow = (show) => {
      setShow(show);
      document.body.classList.remove('no-scroll');
  }

  

  return (
    <>
      {show && (
        <CreationFullPage
          titre={title}
          description={description}
          image={imageToDisplay}
          handleShow={handleShow}
        />
      )}

      <section
        id="creations"
        className="d-flex flex-column align-items-center bg-primary pt-3"
      >
        <img src={creations} alt="Mes créations" style={{ width: "250px" }} />
        <div className="col-md-10 row d-flex justify-content-center mt-5">
          <div data-aos="fade-right" className="card mt-5" onClick={() => handleShowCreation(illustration1, 'Animaux en Flat Design', 'Projet Universitaire \n L\'objectif était de réaliser une collection d\'animaux en flat design. \n Logiciel utilisé : Illustrator de la suite Adobe.')}>
            <img
              className="imgCard"
              src={illustration1}
              alt="illustration des animaux en flat design"
              width="100%"
            />
            <div id="description">
              <h6 className="mt-3">Animaux en Flat Design</h6>
            </div>
          </div>
          <div data-aos="fade-down" className="card mt-5" onClick={() => handleShowCreation(illustration2, 'Couverture de livre pour une histoire d\'horreur', 'Projet Universitaire \n L\'objectif était de réaliser une couverture pour une histoire d\'horreur en anglais. \n Logiciel utilisé : Photoshop de la suite Adobe.')}>
            <img
              className="imgCard"
              src={illustration2}
              alt="illustration de la couverture de livre"
              width="100%"
            />
            <div id="description">
              <h6 className="mt-3">
                Couverture de livre pour une histoire d'horreur
              </h6>
            </div>
          </div>
          <div data-aos="fade-left" className="card mt-5" onClick={() => handleShowCreation(illustration3, 'Dessin graphique du Roi Lion', 'Projet Personnel \n J\'ai voulu réaliser un dessin avec ma tablette graphique. J\'ai donc dessiné des personnages du film d\'animation du Roi Lion. \n Logiciel utilisé : FireAlcapa64.')}>
            <img
              className="imgCard"
              src={illustration3}
              alt="Dessin graphique du Roi Lion"
              width="100%"
            />
            <div id="description">
              <h6 className="mt-3">
                Dessin du Roi Lion sur tablette graphique
              </h6>
            </div>
          </div>
          <div data-aos="fade-right" className="card mt-5" onClick={() => handleShowCreation(illustration4, 'Pochette de l\'album Loud de Rihanna en flat design', 'Projet Universitaire \n L\'objectif était de simplifier, en flat design, une pochette d\'album déjà existante. \n Logiciel utilisé : Illustrator de la suite Adobe.')}>
            <img
              className="imgCard"
              src={illustration4}
              alt="Illustration de la pochette de l'album Loud de Rihanna en flat design"
              width="100%"
            />
            <div id="description">
              <h6 className="mt-3">
                Pochette de l'album Loud de Rihanna en Flat Design
              </h6>
            </div>
          </div>
          <div data-aos="fade-up" className="card mt-5" onClick={() => handleShowCreation(illustration5, 'Pochette d\'album des Guns N Roses en flat design', 'Projet Universitaire \n L\'objectif était de simplifier, en flat design, une pochette d\'album déjà existante. \n Logiciel utilisé : Illustrator de la suite Adobe.')}>
            <img
              className="imgCard"
              src={illustration5}
              alt="Illustration de la pochette de l'album des Guns N Roses en flat design"
              width="100%"
            />
            <div id="description">
              <h6 className="mt-3">
                Pochette de l'album des Guns N Roses en flat design
              </h6>
            </div>
          </div>
          <div data-aos="fade-left"  className="card mt-5" onClick={() => handleShowCreation(illustration6, 'Création de logos pour une application factice de voyages spatiaux', 'Projet Universitaire \n L\'objectif était de créer un logo pour une agence factice de voyages spatiaux. Voici ma proposition de logos. \n Logiciel utilisé : Illustrator de la suite Adobe.')}>
            <img
              className="imgCard"
              src={illustration6}
              alt="Propositions des logos pour l'application de voyages spatiaux"
              width="100%"
            />
            <div id="description">
              <h6 className="mt-3">
                Création de logos pour une application factice de voyages spatiaux
              </h6>
            </div>
          </div>
          <div data-aos="fade-left"  className="card mt-5" onClick={() => handleShowCreation(illustration7, 'Refonte du site de l\'association AVHEC', 'Projet Universitaire \n L\'objectif était de faire la refonte du site de l\'association AVHEC. \n Logiciel utilisé : Figma, Illustrator et Photoshop de la suite Adobe.')}>
            <img
              className="imgCard"
              src={illustration7}
              alt="Proposition de refonte du site de l'association AVHEC"
              width="100%"
            />
            <div id="description">
              <h6 className="mt-3">
                Refonte du site de l'association AVHEC.
              </h6>
            </div>
          </div>
          <div data-aos="fade-left"  className="card mt-5" onClick={() => handleShowCreation(illustration8, 'Création d\'une application factice pour M\'T Dents', 'Projet Universitaire \n L\'objectif était de proposer une application, factice, pour M\'T Dents. \n Logiciel utilisé : Figma.')}>
            <img
              className="imgCard"
              src={illustration8}
              alt="Création d'une application factice pour M'T Dents"
              width="100%"
            />
            <div id="description">
              <h6 className="mt-3">
                Création d'une application factice pour M'T Dents.
              </h6>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Page2;
